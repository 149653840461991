  
  <template>
    <div>
    <MyNav />
    <div v-if="item" class="edit-form py-3">
      <center><h4 class="headline">Actualizar Publicación</h4></center>
      <!-- <center><button class="btn btn-danger" @click="fetchData()">Validar Información</button></center> -->
      <div class="form-group">
            <label>Precio Anterior</label>
            <input disabled type="text" class="form-control" style="color: red; border: dotted;" v-model="precioanterior" placeholder="$"/>
        </div>
        <div class="form-group">
            <label>Precio Minimo</label>
            <input type="text" class="form-control" style="color: red; border: dotted;" v-model="item.preciominimo" placeholder="$"/>
        </div>


      <v-form ref="form" lazy-validation>
        <v-text-field
          v-model="item.publicacion"
          :rules="[(v) => !!v || '# de Publicacion requerido']"
          label="# de Publicacion"
          disabled
          required
        ></v-text-field>
  
        <v-text-field
          v-model="item.sku"
          :rules="[(v) => !!v || 'Titulo']"
          label="Titulo"
          disabled
          required
        ></v-text-field>

        <v-text-field
          v-model="item.precio"
          :rules="[(v) => !!v || 'Precio requerido']"
          label="Precio Final"
          required
          style="color: red;"
        ></v-text-field>

        <v-text-field
          v-model="item.cantidad"
          :rules="[(v) => !!v || 'Stock requerido']"
          label="Stock"
          type="number"
          required
        ></v-text-field>


        <div class="form-group">
            <label>Días para Envio</label>
            <select class="form-select" v-model="item.manufacturing">
            <option>{{ item.manufacturing }}</option>
            <option></option>
            <option>2 días</option>
            <option>3 días</option>
            <option>4 días</option>
            <option>5 días</option>
            <option>6 días</option>
            <option>7 días</option>
            </select>
        </div>

        <!-- <br><v-text-field
          v-model="item.porcentaje"
          :rules="[(v) => !!v || '% de Publicacion requerido']"
          label="% de Publicacion"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.costoenvio"
          :rules="[(v) => !!v || 'Costo de Envio requerido']"
          label="Costo de Envio"
          required
        ></v-text-field> -->

        <div class="form">
          <label>Canal</label>
            <select class="form-select" v-model="item.canal">
            <option>{{item.canal}}</option>
            <option>marketplace</option>
            <option>mshops</option>
            <option>marketplace,mshops</option>
            </select>
        </div>


        
        <v-divider class="my-2"></v-divider>
  
          
  
        <br><v-btn  color="gray" small @click="updateTutorial(item.id)">
          UPDT S/V
        </v-btn>
        <v-btn color="red" small class="mr-2" @click="DeleteApi(item.id)">
          Eliminar
        </v-btn>
        <v-btn color="red" small class="mr-2" @click="cancelar()">
          Cancelar
        </v-btn>

      </v-form>
      <br><center><button class="btn btn-danger" @click="fetchData()">Validar Precio y Actualizar</button></center>

  
      <p class="mt-3">{{ message }}</p>
    </div>
  
    <div v-else>
      <p>Selecciona un producto...</p>
    </div>
  </div>
  </template>
  
  
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.5.7/vuetify.min.js" integrity="sha512-BPXn+V2iK/Zu6fOm3WiAdC1pv9uneSxCCFsJHg8Cs3PEq6BGRpWgXL+EkVylCnl8FpJNNNqvY+yTMQRi4JIfZA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js" integrity="sha512-bZS47S7sPOxkjU/4Bt0zrhEtWx0y0CRkhEp8IckzK+ltifIIE9EMIMTuT/mEzoIMewUINruDBIR/jJnbguonqQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>

  
  <script>
  
  import axios from "axios";
  import {mapGetters} from 'vuex';
  import TutorialDataService from "../services/TutorialDataService";

  
  const baseUrl = "https://mercapi.rapoo.mx/api/productos";
  const baseUrladd = "https://mercapi.rapoo.mx/api/productos/create";
  const baseUrlupdate = "https://mercapi.rapoo.mx/api/productos/actualizar/";
  const baseUrldelete = "https://mercapi.rapoo.mx/api/productos/eliminar/";
  const baseUrlprice = "https://api.mercadolibre.com/items/";
  
  export default {
  
    name: "EditarCRUD",
    computed: {
            ...mapGetters(['me'])
        },
  
        data(){
  
  return{

    todos:[],

    currentitem: null,
      message: "",


    //nameis:'',
    id: null,
    publicacion: '',
    sku:'',
    precio:'',
    preciominimo:'',
    cantidad:'',
    manufacturing:'',
    porcentaje:'',
    costoenvio:'',
    enviogratis:'',
    canal:'',
    estado: '',
    search: "",
    precioanterior: '',
    status:false,

    headers: [
        { text: "# PUblicacion", align: "start", sortable: false, value: "publicacion" },
        { text: "SKU", align: "start", sortable: false, value: "sku" },
        { text: "Precio Minimo", align: "start", sortable: false, value: "preciominimo" },
        { text: "Precio Final", align: "start", sortable: false, value: "precio" },
        { text: "Stock", align: "start", sortable: false, value: "cantidad" },
        { text: "Días para Envio", align: "start", sortable: false, value: "manufacturing" },
        { text: "% de Publicacion", value: "porcentaje", sortable: false },
        { text: "Costo de Envio", value: "costoenvio", sortable: false },
        { text: "Envio Gratis", value: "enviogratis", sortable: true },
        { text: "Canal", value: "canal", sortable: true },
        { text: "Estado", value: "estado", sortable: true },
        { text: "Acciones", value: "actions", sortable: false },
  ],
  item:[],
  detailedit:{},
  dialogdetail:false

  }

},

methods: {

  async GetApi(id) {

    await axios

      .get(baseUrl)

      .then((resp) => {

        this.item = resp.data;

      })

      .catch((err) => {

        console.log(err);

      });

  },

  getTutorial(id) {
      TutorialDataService.get(id)
        .then((response) => {
          this.item = response.data;
          console.log(response.data);
          this.fetchDatados();                     ///////SE AGREGO LA VALIDACION DE PRECIO
        })
        .catch((e) => {
          console.log(e);
        });
    },

    deleteTutorial(id) {
      TutorialDataService.delete(id)
        .then(() => {
          alert("Producto Eliminado Correctamente");

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });
    },



updateTutorial(id) {
      TutorialDataService.update(this.item.id, this.item)
      .then(response => {

        console.log('PRUEBA');
        // 		this.http.post('data.php',{ 

        axios({
          method: 'POST',
                headers: { 'content-type': 'application/form-data' },
        // baseURL: 'prueba/',
      url: 'dataphps',
        data: {
          publicacion: this.item.publicacion,
          precio: this.item.precio,
          cantidad: this.item.cantidad,
          estado: this.item.estado,
          manufacturing: this.item.manufacturing,
          canal: this.item.canal
          // password: password
        }
      }).then(response => {
        this.item = 'prueba';
              this.publicacion="";
              this.precio="";
              this.cantidad="";
              this.estado="";
              this.manufacturing="";
              this.canal="";
              // this.telefono="";
      })
      .catch(error => {
        console.log(error)
      });

        this.$swal({
  position: 'top-end',
  icon: 'success',
  title: 'Publicación actualizada correctamente, regresando al CRUD',
  showConfirmButton: false,
  timer: 2500,
  
})
this.$router.push('../MiCRUD')    
        })
        .catch(e => {
          console.log(e);
        });
    },


    async fetchData() {

await axios

.get(baseUrlprice+this.item.publicacion)

.then((resp) => {

  // this.item.precio = resp.data.price;
  this.precioanterior = resp.data.price;

  if (this.precioanterior > this.item.precio) {

    this.$swal({
  position: 'top-end',
  icon: 'error',
  title: 'El PRECIO ANTERIOR ES MAYOR AL PRECIO ACTUAL, CORRIGE EL PRECIO ANTES DE ACTUALIZAR',
  showConfirmButton: false,
  timer: 3000,
  
})
    // alert(" ❌❌ El PRECIO ANTERIOR ES MAYOR AL PRECIO ACTUAL ❌❌ : CORRIGE EL PRECIO ANTES DE ACTUALIZAR");
    this.$router.push('../MiCRUD')    
  }else{

    this.updateTutorial();

  }

})

.catch((err) => {

console.log(err);

});

},  



async fetchDatados() {

await axios

.get(baseUrlprice+this.item.publicacion)

.then((resp) => {

  // this.item.precio = resp.data.price;
  this.precioanterior = resp.data.price;

})

.catch((err) => {

console.log(err);

});

},


//     updateTutorial(id) {
//       TutorialDataService.update(this.item.id, this.item)
//       .then(response => {

//         console.log('PRUEBA');
//         this.$swal({
//   position: 'top-end',
//   icon: 'success',
//   title: 'Publicación actualizada correctamente, regresando al CRUD',
//   showConfirmButton: false,
//   timer: 1500,
  
// })
// this.$router.push('../MiCRUD')    
//         })
//         .catch(e => {
//           console.log(e);
//         });
//     },






  retrieveTutorials() {
  TutorialDataService.getAll()
    .then((response) => {
      this.productos = response.data.map(this.getDisplayTutorial);
      console.log(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
},

  refreshList() {
  this.GetApi();
},



EditarCRUD(item) {

    this.id = item.id
    // console.log(item.id)
  this.$router.push({ name: "EditarCRUD", params: {id:this.id} });
},

cancelar() {
  // this.$swal('Hello Vue world!!!');
  this.$swal({
  position: 'top-end',
  icon: 'info',
  title: 'Cancelado, regresando al CRUD',
  showConfirmButton: false,
  timer: 1500
})
   this.$router.push('../MiCRUD')    
},



  // async DeleteApi(id) {

  //   await axios

  //     .delete(baseUrldelete+id)

  //     .then((resp) => {
        
  //       console.log(resp);
  //       alert("Producto Eliminado Correctamente");
  //       this.$router.push('../MiCRUD')    


  //       this.GetApi();

  //     })

  //     .catch((err) => {

  //       console.log(err);

  //     });

  // },




  async DeleteApi(id) {

await axios

  .delete(baseUrldelete+id)

  .then(response => {
          this.$swal({
              position: 'top-end',
              icon: 'success',
              title: 'Publicación eliminada en BD correctamente, regresando al CRUD',
              showConfirmButton: false,
              timer: 2000
            })
              this.$router.push('../MiCRUD')   
              this.GetApi();
 
        })
        .catch(e => {
          console.log(e);
        });
    },


},

mounted(){


  this.message = "";
    this.getTutorial(this.$route.params.id);

}

};

</script>


<style>
.list {
max-width: 750px;
}
</style>



<!-- <style>

#app {

font-family: Avenir, Helvetica, Arial, sans-serif;

-webkit-font-smoothing: antialiased;

-moz-osx-font-smoothing: grayscale;

color: #2c3e50;

margin-top: 60px;

}

</style>
-->
