<template>
    <div>
        <!-- <form v-if="me" @submit.prevent="handleSubmit"> -->
    
            <center><button class="btn btn-warning" @click="fetchData">Traer Información</button>
            <div class="form-group col-sm-3">
                <label># Publicación</label>
            <p style="color: red;">Asegurate de ingresar el código de barras y confirmar la creación del producto en M.L.</p>
                <input type="text" class="form-control" v-model="publicacion" placeholder="MLM#########"/>
            </div></center>

   
    
     <br><form @submit.prevent="handleSubmit">
 
 <div class="container-fluid bg-3 text-center">    
   <h3>Informacion General</h3>
   <div class="row">
        <div class="col-sm-3">
        </div>
     <div class="col-sm-2">
       <p>Titulo</p>
                 <input type="text" class="form-control" v-model="titulo" placeholder="Titulo"/>
     </div>
     <div class="col-sm-1"> 
       <p>Precio</p>
                 <input type="text" class="form-control" v-model="precio" placeholder="Precio"/>
     </div>
     <div class="col-sm-1"> 
       <p>Cantidad</p>
                 <input type="text" class="form-control" v-model="cantidad" placeholder="Stock"/>
     </div>
     <div class="col-sm-1">
       <p>ID Categoria</p>
                 <input disabled type="text" class="form-control" v-model="categoriaid" placeholder="ID Categoria"/>
     </div>
     <div class="col-sm-1">
       <p>ID Moneda</p>
                 <input disabled type="text" class="form-control" v-model="currencyid" placeholder="Currency"/>
     </div>
     <div class="col-sm-3">
        </div>

   </div>
 </div><br><br>
 
 <div class="container-fluid bg-3 text-center">    
   <h3>Información del producto</h3><br>
   <div class="row">
     <div class="col-sm-2"> 
       <p>Tipo de publicación</p>
                 <input disabled type="text" class="form-control" v-model="buying_mode" placeholder="Modo de compra"/>
     </div>
     <div class="col-sm-2"> 
       <p>Condición</p>
                 <input disabled type="text" class="form-control" v-model="condition" placeholder="Condicion"/>
     </div>
     <div class="col-sm-2">
       <p>ID</p>
                 <input disabled type="text" class="form-control" v-model="sale_terms_warranty_type_id" placeholder="Tipo Garantia"/>
     </div>
         <div class="col-sm-2">
       <p>Tipo de Garantía</p>
                 <input type="text" class="form-control" v-model="sale_terms_warranty_type_value" placeholder="Valor Garantia"/>
     </div>
 
     <div class="col-sm-2">
       <p>ID</p>
                 <input disabled type="text" class="form-control" v-model="sale_terms_warranty_time_id" placeholder="Garantia Tiempo"/>
     </div>
     <div class="col-sm-2">
       <p>Valor días</p>
                 <input type="text" class="form-control" v-model="sale_terms_warranty_time_value" placeholder="Garantia Tiempo Valor"/>
     </div>

 
   </div>
 </div><br><br>
 
 <div class="container-fluid bg-3 text-center">    
    <h3>Imagenes</h3><br><p style="color: red;">Se cargan automáticamente de la publicación base.</p>
 </div><br><br>
 
 <div class="container-fluid bg-3 text-center">    
   <h3>Información Adicional</h3><br>
   <div class="row">
     <div class="col-sm-1">
       <p>ID</p>
                 <input disabled type="text" class="form-control" v-model="attributes_brand_id" placeholder="ID Marca"/>
     </div>
     <div class="col-sm-2"> 
       <p>Marca</p>
                 <input type="text" class="form-control" v-model="attributes_brand_value" placeholder="Valor Marca"/>
     </div>
     <div class="col-sm-1"> 
       <p>ID</p>
                 <input disabled type="text" class="form-control" v-model="attributes_ean_id" placeholder=" ID BarCode"/>
     </div>
     <div class="col-sm-2"> 
       <p>Variation</p>
                 <input disabled type="text" class="form-control" v-model="variation" placeholder="Variacion"/>
     </div>
     <div class="col-sm-2"> 
       <p>Catalog ID</p>
                 <input disabled type="text" class="form-control" v-model="catalog_product_id" placeholder=" ID BarCode"/>
     </div>
     <div class="col-sm-2"> 
       <p>Listing type</p>
                 <input type="text" class="form-control" v-model="listingtype" placeholder=" Listing Type"/>
     </div>


     <div class="col-sm-2">
       <p>Código de Barras</p>
                 <input type="text" class="form-control" v-model="attributes_ean_value" placeholder="Value BarCode"/>
 
   </div>
 </div><br>
             <br><button class="btn btn-primary btn-block">Dar de ALTA</button>
 </div>
         </form>
     
        <!-- <form v-if="!me" @submit.prevent="handleSubmit">
            <h3> Registro </h3>
        
            <div class="form-group">
                <label>No has iniciado sesion</label>
            </div>
        </form> -->
    </div>
    </template>
        
    
    <!-- <template>
    <div>
        <h3 v-if="me">Bienvenido {{me.name}}</h3>
        <h3 v-if="!me">No has iniciado sesion</h3>
    </div>
        </template> -->
        
    
        
    
    <script>
    //   import TutorialDataService from "../services/TutorialDataService";
    
    const baseUrl = "https://api.mercadolibre.com/items/";
    
    import {mapGetters} from 'vuex';
    
    import axios from 'axios'
    
        export default{
    
                name: 'ConsumoApi',
                computed: {
                ...mapGetters(['me'])
            },
                data(){
    
                    return{
                        publicacion: '',
                        precio:'',
                        cantidad:'',
                        titulo: '',
                        categoriaid: '',
                        currencyid: '',
                        buying_mode: '',
                        condition: '',
                        listingtype: '',
                        sale_terms_warranty_type_id: '',
                        sale_terms_warranty_type_value: '',
                        sale_terms_warranty_time_id: '',
                        sale_terms_warranty_time_value: '',
                        attributes_brand_id: '',
                        attributes_brand_value: '',
                        attributes_ean_id: '',
                        attributes_ean_value: '',
                        variation: '',
                        catalog_product_id: ''

                    }
    
                },
                methods: {
                    async handleSubmit(){
    
                      await axios.post('dataphpsalta', {
                        
                        publicacion: this.publicacion,
                        precio: this.precio,
                        cantidad: this.cantidad,
                        titulo: this.titulo,
                        categoriaid: this.categoriaid,
                        currencyid: this.currencyid,
                        buying_mode: this.buying_mode,
                        condition: this.condition,
                        listingtype: this.listingtype,
                        sale_terms_warranty_type_id: this.sale_terms_warranty_type_id,
                        sale_terms_warranty_type_value: this.sale_terms_warranty_type_value,
                        sale_terms_warranty_time_id: this.sale_terms_warranty_time_id,
                        sale_terms_warranty_time_value: this.sale_terms_warranty_time_value,
                        attributes_brand_id: this.attributes_brand_id,
                        attributes_brand_value: this.attributes_brand_value,
                        attributes_ean_id: this.attributes_ean_id,
                        attributes_ean_value: this.attributes_ean_value,
                        variation: this.variation,
                        catalog_product_id: this.catalog_product_id
                        });
                        
                        this.$swal({
                  position: 'top-end',
                  icon: 'info',
                  title: 'Publicación creada, verifica la publicación en Mercado Libre... regresando al CRUD',
                  showConfirmButton: false,
                  timer: 4000
                })
                  this.$router.push('MiCRUD')    
    
                    },
    
    
    
                    async fetchData() {
    
                        await axios
    
                        .get(baseUrl+this.publicacion)
    
                        .then((resp) => {
                        
                        this.precio = resp.data.price;
                        this.cantidad = resp.data.available_quantity;

                        this.titulo = resp.data.title;
                        this.categoriaid = resp.data.category_id;
                        this.currencyid = "MXN"
                        this.buying_mode = "buy_it_now";
                        this.condition = "new";
                        this.listingtype = resp.data.listing_type_id;
                        this.sale_terms_warranty_type_id = "WARRANTY_TYPE";
                        this.sale_terms_warranty_type_value = "Garantía del vendedor";
                        this.sale_terms_warranty_time_id = "WARRANTY_TIME";
                        this.sale_terms_warranty_time_value = "30 días";
                        this.attributes_brand_id = "BRAND";

                        if (resp.data.attributes[0].id == "BRAND") {
                            this.attributes_brand_value = resp.data.attributes[0].value_name; 
                        }else if(resp.data.attributes[1].id == "BRAND"){
                            this.attributes_brand_value = resp.data.attributes[1].value_name; 
                        }else if(resp.data.attributes[2].id == "BRAND"){
                            this.attributes_brand_value = resp.data.attributes[2].value_name; 
                        }else if(resp.data.attributes[3].id == "BRAND"){
                            this.attributes_brand_value = resp.data.attributes[3].value_name; 
                        }else if(resp.data.attributes[4].id == "BRAND"){
                            this.attributes_brand_value = resp.data.attributes[4].value_name; 
                        }else if(resp.data.attributes[5].id == "BRAND"){
                            this.attributes_brand_value = resp.data.attributes[5].value_name; 
                        }else if(resp.data.attributes[6].id == "BRAND"){
                            this.attributes_brand_value = resp.data.attributes[6].value_name; 
                        }else if(resp.data.attributes[7].id == "BRAND"){
                            this.attributes_brand_value = resp.data.attributes[7].value_name; 
                        }else if(resp.data.attributes[8].id == "BRAND"){
                            this.attributes_brand_value = resp.data.attributes[8].value_name; 
                        }else if(resp.data.attributes[9].id == "BRAND"){
                            this.attributes_brand_value = resp.data.attributes[9].value_name; 
                        }else{
                            this.attributes_brand_value = "";
                        }

                        this.attributes_ean_id = "GTIN";

                        if (resp.data.attributes[0].name == "Código universal de producto") {
                            this.attributes_ean_value = resp.data.attributes[0].value_name; 
                        }else if(resp.data.attributes[1].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[1].value_name; 
                        }else if(resp.data.attributes[2].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[2].value_name; 
                        }else if(resp.data.attributes[3].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[3].value_name; 
                        }else if(resp.data.attributes[4].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[4].value_name; 
                        }else if(resp.data.attributes[5].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[5].value_name; 
                        }else if(resp.data.attributes[6].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[6].value_name; 
                        }else if(resp.data.attributes[7].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[7].value_name; 
                        }else if(resp.data.attributes[8].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[8].value_name; 
                        }else if(resp.data.attributes[9].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[9].value_name; 
                        }else if(resp.data.attributes[10].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[10].value_name; 
                        }else if(resp.data.attributes[11].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[11].value_name; 
                        }else if(resp.data.attributes[12].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[12].value_name; 
                        }else if(resp.data.attributes[13].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[13].value_name; 
                        }else if(resp.data.attributes[14].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[14].value_name; 
                        }else if(resp.data.attributes[15].name == "Código universal de producto"){
                            this.attributes_ean_value = resp.data.attributes[15].value_name; 
                        }else{
                            this.attributes_ean_value = "";
                        }


                        // if (isSet(resp.data.pictures[0].url)) {
                        //     this.picture_1 = resp.data.pictures[0].url;                            
                        // }else{
                        //     this.picture_1 =' ';
                        // }
                        // if (isSet(resp.data.pictures[1].url)) {
                        //     this.picture_2 = resp.data.pictures[1].url;                            
                        // }else{
                        //     this.picture_2 =' ';
                        // }
                        // if (isSet(resp.data.pictures[2].url)) {
                        //     this.picture_3 = resp.data.pictures[2].url;                            
                        // }else{
                        //     this.picture_3 =' ';
                        // }
                        // if (isSet(resp.data.pictures[3].url)) {
                        //     this.picture_4 = resp.data.pictures[3].url;                            
                        // }else{
                        //     this.picture_4 =' ';
                        // }
                        // if (isSet(resp.data.pictures[4].url)) {
                        //     this.picture_5 = resp.data.pictures[4].url;                            
                        // }else{
                        //     this.picture_5 =' ';
                        // }
                        // if (isSet(resp.data.pictures[5].url)) {
                        //     this.picture_6 = resp.data.pictures[5].url;                            
                        // }else{
                        //     this.picture_6 =' ';
                        // }
                        // if (isSet(resp.data.pictures[6].url)) {
                        //     this.picture_7 = resp.data.pictures[6].url;                            
                        // }else{
                        //     this.picture_7 =' ';
                        // }
                        // if (isSet(resp.data.pictures[7].url)) {
                        //     this.picture_8 = resp.data.pictures[7].url;                            
                        // }else{
                        //     this.picture_8 =' ';
                        // }
                        // if (isSet(resp.data.pictures[8].url)) {
                        //     this.picture_9 = resp.data.pictures[8].url;                            
                        // }else{
                        //     this.picture_9 =' ';
                        // }
                        // this.picture_1 = resp.data.pictures[0].url;
                        // this.picture_2 = resp.data.pictures[1].url;
                        // this.picture_3 = resp.data.pictures[2].url;
                        // this.picture_4 = resp.data.pictures[3].url;
                        // this.picture_5 = resp.data.pictures[4].url;
                        // this.picture_6 = resp.data.pictures[5].url;
                        // this.picture_7 = resp.data.pictures[6].url;
                        // this.picture_8 = resp.data.pictures[7].url;
                        // this.picture_9 = resp.data.pictures[8].url;

                        if (resp.data.variations[0].attribute_combinations[0].value_name === '') {
                            this.variation = 'HOLAMUNDO';
                        }else{
                            this.variation = resp.data.variations[0].attribute_combinations[0].value_name;

                        }

                        if (resp.data.catalog_product_id === '') {
                            this.catalog_product_id = 'HOLAMUNDO';
                        }else{
                            this.catalog_product_id = resp.data.catalog_product_id;

                        }


    
                        })
    
                        .catch((err) => {
    
                        console.log(err);
    
                        });
    
                        }                
    
                }
        }
    
    </script>
    
    